import { useState, useEffect } from 'react'
import { X } from 'lucide-react'
import { Button } from 'components/ui/Button'
import ScrollContainer from 'components/core/ScrollContainer'
import HeaderNew from 'components/core/HeaderNew'
import { Table } from 'components/core/v2/Table'
import { DealsMobileFilter } from 'components/core/v2/deals/MobileFilters'
import { Filters } from 'components/core/v2/deals/Filters'
import { useGetOpportunities, useGetOpportunitiesByOpportunityType } from 'hooks/api/useQuery.hooks'
import type { OpportunityResponse } from 'types/api-types'
import { Opportunity } from 'services/DataStore'
import useDebounce from 'services/useDebounce'
import { NewDealDialog } from 'components/core/v2/deals/NewDealDialog'
import { columns } from 'components/core/v2/deals/Columns'
import { useTablePagination, useTable, useTableSort } from 'hooks/useTable.hooks'
import { TablePagination } from 'components/core/v2/TablePagination'

interface DefaultPagination {
  order_by: string
  limit: number
  offset: number
  current_page: number
  admin_list: boolean
  order?: string
}

interface DefaultFilters {
  search: string
  status: string[]
  vintages: number[]
  visibility: { show_in_portfolio: boolean; visible_on_mvp: boolean }
}

const LOCALSTORAGE_NAME = 'mvp_deals_sq'

const DEFAULT_PAGINATION = {
  order_by: 'closes',
  limit: 50,
  offset: 0,
  current_page: 1,
  admin_list: true
}
const DEFAULT_FILTERS: DefaultFilters & { pagination: DefaultPagination } = {
  search: '',
  status: ['active', 'inactive'],
  vintages: [],
  visibility: { show_in_portfolio: true, visible_on_mvp: true },
  pagination: DEFAULT_PAGINATION
}

const getLocalStorageSearchQuery = () => {
  const searchQuery = localStorage.getItem(LOCALSTORAGE_NAME)
  if (!searchQuery) return DEFAULT_FILTERS
  return JSON.parse(searchQuery) as DefaultFilters & { pagination: DefaultPagination }
}

export default function Deals() {
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const [search, setSearch] = useState<string>(() => getLocalStorageSearchQuery().search)
  const [status, setStatus] = useState<string[]>(() => getLocalStorageSearchQuery().status)
  const [vintages, setVintages] = useState<number[]>(() => getLocalStorageSearchQuery().vintages)
  const debouncedSearch = useDebounce(search, 500)

  const { pagination, onPaginationChange } = useTablePagination({
    pageIndex: getLocalStorageSearchQuery().pagination.current_page - 1,
    pageSize: getLocalStorageSearchQuery().pagination.limit
  })
  const { sorting, onSortingChange, orderBy, order } = useTableSort([
    {
      id: getLocalStorageSearchQuery().pagination.order_by,
      desc: !!(getLocalStorageSearchQuery().pagination.order === 'desc')
    }
  ])

  const { data, isLoading, isFetching } = useGetOpportunities(true, {
    order_by: orderBy,
    order,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    current_page: pagination.pageIndex + 1,
    admin_list: true,
    search_term: debouncedSearch,
    vintages: debouncedSearch.length ? undefined : vintages,
    status: debouncedSearch.length ? undefined : status
  })

  const tableData = data?.data.map(d => ({ ...d, linkUrl: `/admin/deals/${d.slug}` }))

  const table = useTable<OpportunityResponse>({
    columns,
    data: tableData ?? [],
    // debugTable: true,
    manualSorting: true,
    manualPagination: true,
    onSortingChange,
    onPaginationChange,
    pageCount: Math.ceil(+(data?.count ?? 1) / pagination.pageSize),
    state: { pagination, sorting },
    isLoading: isLoading || isFetching
  })

  const saveToLocalStorage = () => {
    const pageinationToStore = {
      order_by: orderBy,
      order,
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      current_page: pagination.pageIndex + 1,
      admin_list: true
    }

    localStorage.setItem(
      LOCALSTORAGE_NAME,
      JSON.stringify({ search: debouncedSearch, status, vintages, pagination: pageinationToStore })
    )
  }

  const resetTable = () => {
    // force table to start from the beginning
    table.setPageIndex(0)
  }

  const resetFilters = () => {
    setSearch('')
    setStatus(DEFAULT_FILTERS.status)
    setVintages([])
    table.setSorting([])
  }

  useEffect(() => {
    saveToLocalStorage()
  }, [saveToLocalStorage])

  return (
    <ScrollContainer
      hasHeader
      className=''
      goToTop={undefined}
      setGoToTop={undefined}
      isScrolled={isScrolled}
      setIsScrolled={setIsScrolled}
    >
      <HeaderNew />

      <div className='max-w-[1500px] mx-auto px-4 isolate'>
        <div className='flex flex-col mdlg:items-center mdlg:flex-row mt-10'>
          <h1 className='m-0 text-4xl montserrat font-bold'>
            Deals {data?.count && <span className='text-2xl font-normal'>({data.count})</span>}
          </h1>

          <div className='flex items-center mt-4 mdlg:ml-auto'>
            <DealsMobileFilter
              filterCount={status.length + vintages.length}
              className='inline-block mdlg:hidden'
            >
              <Filters
                search={search}
                status={status}
                vintages={vintages}
                setSearch={setSearch}
                setStatus={setStatus}
                setVintages={setVintages}
                resetTable={resetTable}
                className='flex-col space-x-0 space-y-6'
              />
              <div className='flex space-x-4 mt-6'>
                <Button
                  variant='outline'
                  size='sm'
                  className='flex-1 border-[#DEDEE3]'
                  onClick={resetFilters}
                >
                  Reset
                </Button>
                {/* This is a dummy UI button. All the filters are applied when filter logics apply. */}
                <Button size='sm' className='flex-1 bg-[#6DB557]'>
                  Apply
                </Button>
              </div>
            </DealsMobileFilter>

            <Filters
              search={search}
              status={status}
              vintages={vintages}
              setSearch={setSearch}
              setStatus={setStatus}
              setVintages={setVintages}
              resetTable={resetTable}
              className='hidden mdlg:flex'
            />

            <Button
              variant='ghost'
              size='sm'
              onClick={resetFilters}
              className='mx-4 hidden mdlg:flex'
            >
              Reset
              <X className='w-4 h-4 ml-1' />
            </Button>

            <div className='ml-auto'>
              <NewDealDialog />
            </div>
          </div>
        </div>

        <Table<OpportunityResponse> table={table} className='mt-4 md:mt-8' isClickableRow />
        <TablePagination table={table} />
      </div>
    </ScrollContainer>
  )
}

import { SubmitHandler, useForm } from 'react-hook-form'
import z from 'zod'
import { Check } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { UserInfo } from 'types/api-types'
import { zodResolver } from '@hookform/resolvers/zod'
import { useResetPasswordMutate } from 'hooks/api/useMutation.hooks'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { PasswordInput } from 'components/ui/PasswordInput'
import { Button } from 'components/ui/Button'
import { LoadingButton } from 'components/ui/LoadingButton'
import { useToast } from 'hooks/userToast.hooks'

interface Props {
  user: UserInfo | undefined
  open: boolean
  toggleOpen: (open: boolean) => void
}

const passwordSchema = z
  .object({
    currentPassword: z.string().min(1, { message: 'Current password is required.' }),
    newPassword: z.string().min(8, { message: 'Password must be longer than 8 characters.' }),
    confirmPassword: z.string()
  })
  .superRefine(({ newPassword, confirmPassword }, ctx) => {
    if (newPassword !== confirmPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'Password does not match.',
        path: ['confirmPassword']
      })
    }
  })
type PasswordSchema = z.infer<typeof passwordSchema>

export function ResetPasswordDialog({ user, open, toggleOpen }: Props) {
  const { mutate, isLoading } = useResetPasswordMutate()

  const { toast } = useToast()

  const form = useForm<PasswordSchema>({
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  })

  const handleSubmit: SubmitHandler<PasswordSchema> = values => {
    mutate(values, {
      onSuccess: () => {
        toast({
          description: (
            <span className='flex items-center space-x-2'>
              <span>Password has been reset</span>
              <Check color='#52B749' className='w-4 h-3' />
            </span>
          ),
          duration: 2000
        })
        toggleOpen(false)
      },
      onError: error => {
        toast({
          variant: 'destructive',
          description: error.message ?? 'Something went wrong',
          duration: 2000
        })
      }
    })
  }

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[1035px]'>
        <DialogHeader>
          <DialogTitle className='text-3xl font-semibold montserrat'>Reset Password</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form className='mt-4' onSubmit={form.handleSubmit(handleSubmit)}>
            <div className='space-y-4'>
              <FormField
                control={form.control}
                name='currentPassword'
                render={({ field }) => {
                  return (
                    <FormItem className='max-w-[485px]'>
                      <FormLabel>Current Password</FormLabel>
                      <FormControl>
                        <PasswordInput placeholder='******' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='newPassword'
                render={({ field }) => {
                  return (
                    <FormItem className='max-w-[485px]'>
                      <FormLabel>New Password</FormLabel>
                      <FormControl>
                        <PasswordInput placeholder='******' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='confirmPassword'
                render={({ field }) => {
                  return (
                    <FormItem className='max-w-[485px]'>
                      <FormLabel>Confirm Password</FormLabel>
                      <FormControl>
                        <PasswordInput placeholder='******' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                variant='outline'
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-[#52B749]'
              >
                Reset Password
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

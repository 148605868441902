import { currentPlatform, isLoggedIn } from '../services/authentication'

import ChampionMarketing from './loggedOut/ChampionMarketing'
import _Register from './register/_Register'
import Register from 'routes/v2/registration/Register'
import VerifyEmail from './loggedOut/VerifyEmail'
import ConfirmEmail from './loggedOut/ConfirmEmail'
import _NDANeeded from './loggedOut/NDANeeded'
import { NdaNeeded } from 'routes/v2/registration/extraNeededFields/Nda'
import QPNeeded from './loggedOut/QPNeeded'
import QPChampionNeeded from './loggedOut/QPChampionNeeded'
import PendingApproval from './loggedOut/PendingApproval'
import _ForgotPassword from './loggedOut/ForgotPassword'
import ForgotPassword from 'routes/v2/forgot-password/ForgotPassword'
import ResetPassword from './loggedOut/ResetPassword'
import AboutMVP from './loggedOut/AboutMVP'
import MFusion from './loggedOut/MFusion'
import AboutDealShares from './loggedOut/AboutDealShares'
import AboutVintages from './loggedOut/AboutVintages'

import Opportunities from './loggedIn/Opportunities'
import Opportunity from './loggedIn/Opportunity'
import BlogIndex from './loggedIn/BlogIndex'
import BlogDetail from './loggedIn/BlogDetail'
import Companies from './loggedIn/Companies'
import Team from './loggedIn/Team'
import Doc from './loggedIn/Doc'
import FAQ from './loggedIn/FAQ'

// import Deals from './admin/Deals'
import Deals from './v2/admin/deals/Deals'
import Deal from './admin/Deal'
// import Investors from './admin/Investors'
import Investors from './v2/admin/Investors'
import Investor from './admin/Investor'
import Clients from './admin/Clients'
// import Settings from './admin/Settings'

import _Login from './_Login'
import Login from 'routes/v2/login/Login'
import PageNotFound from './PageNotFound'
import DealCommitmentsRoute from './admin/DealCommitmentsRoute'
import DealCarryRoute from './admin/DealCarryRoute'
import DealCarries from './admin/DealCarries'
import PortfolioPage from './loggedIn/PortfolioPage'
import ClientPortfolio from './admin/ClientPortfolio'

let marketingComponent = Login

let platform = 'mvp'
if (currentPlatform() === 'champion') {
  platform = 'champion'

  marketingComponent = ChampionMarketing
}

const routes = [
  // LOGGED OUT ROUTES

  {
    path: '/',
    component: marketingComponent,
    isProtected: false,
    platform: platform
  },
  {
    path: '/clear_forward',
    component: marketingComponent,
    isProtected: false,
    platform: platform
  },
  {
    path: '/register',
    component: Register,
    isProtected: false,
    platform: platform
  },
  {
    path: '/verify-email',
    component: VerifyEmail,
    isProtected: false,
    platform: platform
  },
  {
    path: '/confirm_email',
    component: ConfirmEmail,
    isProtected: false,
    platform: platform
  },
  {
    path: '/nda',
    // component: _NDANeeded,
    component: NdaNeeded,
    isProtected: false,
    platform: platform
  },
  {
    path: '/qualified_purchaser',
    component: QPNeeded,
    isProtected: false,
    platform: platform
  },
  {
    path: '/qualified_purchaser_survey',
    component: QPNeeded,
    isProtected: false,
    platform: platform
  },
  {
    path: '/pending-approval',
    component: PendingApproval,
    isProtected: false,
    platform: platform
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    isProtected: false,
    platform: platform
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    isProtected: false,
    platform: platform
  },
  {
    path: '/about-mvp',
    component: AboutMVP,
    isProtected: false,
    platform: platform
  },
  {
    path: '/mfusion',
    component: MFusion,
    isProtected: false,
    platform: platform
  },
  {
    path: '/about-deal-shares',
    component: AboutDealShares,
    isProtected: false,
    platform: platform
  },
  {
    path: '/about-vintages',
    component: AboutVintages,
    isProtected: false,
    platform: platform
  },

  // LOGGED IN ROUTES

  {
    path: '/opportunities',
    component: Opportunities,
    isProtected: true,
    platform: platform
  },
  {
    path: '/opportunities/:id',
    component: Opportunity,
    isProtected: true,
    platform: platform
  },
  {
    path: '/updates',
    component: BlogIndex,
    isProtected: false,
    platform: platform
  },
  {
    path: '/updates/:slug',
    component: BlogDetail,
    isProtected: false,
    platform: platform
  },
  {
    path: '/companies',
    component: Companies,
    isProtected: false,
    platform: platform
  },
  {
    path: '/team',
    component: Team,
    isProtected: true,
    platform: platform
  },
  {
    path: '/opportunities/:opportunityId/:linkId',
    component: Doc,
    isProtected: true,
    platform: platform
  },
  {
    path: '/portfolio',
    component: PortfolioPage,
    isProtected: true,
    platform: platform
  },
  {
    path: '/faqs',
    component: FAQ,
    isProtected: true,
    platform: platform
  },

  // ADMIN ROUTES

  {
    path: '/admin/deals',
    component: Deals,
    isProtected: true,
    platform: platform
  },
  {
    path: '/admin/deals/:id',
    component: Deal,
    isProtected: true,
    platform: platform
  },
  {
    path: '/admin/deals/:id/commitments',
    component: DealCommitmentsRoute,
    isProtected: true,
    platform: platform
  },
  {
    path: '/admin/deals/:id/carries',
    component: DealCarryRoute,
    isProtected: true,
    platform: platform
  },
  {
    path: '/admin/investors',
    component: Investors,
    isProtected: true,
    platform: platform
  },
  {
    path: '/admin/investors/:id',
    component: Investor,
    isProtected: true,
    platform: platform
  },
  {
    path: '/admin/clients',
    component: Clients,
    isProtected: true,
    platform: platform
  },
  {
    path: '/admin/clients/:user_id/portfolio',
    component: ClientPortfolio,
    isProtected: true,
    platform: platform
  },
  // {
  //   path: '/admin/settings',
  //   component: Settings,
  //   isProtected: true,
  //   platform: platform
  // },
  {
    path: '/*',
    component: PageNotFound,
    isProtected: false,
    platform: platform
  }
]

export default routes

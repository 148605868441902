import { type ColumnDef } from '@tanstack/react-table'
import type { InvestorResponse } from 'types/api-types'
import { dateFormat } from 'services/TimeUtils'

export const columns: ColumnDef<InvestorResponse>[] = [
  {
    accessorKey: 'name',
    header: () => <span>Deal</span>,
    cell: info => {
      const { original } = info.row

      return (
        <>
          <span className='hidden md:flex items-center space-x-3'>
            <span className='text-[#6DB557] font-semibold text-sm mdlg:text-base'>
              {original.name}
            </span>
          </span>

          <div className='flex md:hidden items-center space-x-3'>
            <div className='flex flex-col flex-1 space-y-1'>
              <div className='flex justify-between'>
                <span className='text-[#6DB557] font-semibold text-sm'>{original.name}</span>
              </div>
            </div>
          </div>
        </>
      )
    },
    meta: { thClassName: 'hidden md:table-cell' },
    size: 300
  },
  {
    accessorKey: 'created_at',
    header: () => <span>Created at</span>,
    cell: info => {
      const { created_at } = info.row.original
      return <span className='text-sm mdlg:text-base'>{dateFormat(created_at)}</span>
    },
    meta: { thClassName: 'hidden md:table-cell', tdClassName: 'hidden md:table-cell' }
  }
]

import { Dialog, DialogContent, DialogClose } from 'components/ui/Dialog'
import EditDeal from 'components/EditDeal'
import { X } from 'lucide-react'

export function EditDealDialog({ deal, close, history, open = false }: { deal: any, close: () => void, history: any, open: boolean}) {
  return (
    <Dialog open={open} onOpenChange={isOpen => !isOpen && close()}>
      <DialogContent className='bg-white max-w-[60vw] max-h-[90vh] overflow-y-auto'>
        <DialogClose onClick={close} className="absolute top-[2.5rem] right-6 z-10">
          <X />
        </DialogClose>
        <EditDeal
          deal={deal}
          close={close}
          history={history}
        />
      </DialogContent>
    </Dialog>
  )
}
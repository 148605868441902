import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import { dollarFormat } from '../../constants/DollarsFormat'
import sv, { style } from '../../themes/styles'
import ReactHtmlParser from 'react-html-parser'
import configuration from '../../services/config'
import Header from '../../components/core/Header'
import Button from '../../components/core/Button'
import ScrollContainer from '../../components/core/ScrollContainer'
import StatusDropdown from '../../components/core/StatusDropdown'
import Checkbox from '../../components/core/Checkbox'
import Modal from '../../components/core/Modal'
import EditDeal from '../../components/EditDeal'
import { EditDealDialog } from 'components/core/v2/deals/EditDealDialog'
import MediaItem from '../../components/MediaItem'
import { DataStore, Opportunity } from '../../services/DataStore'
import { isAdmin, isSuperAdmin, currentPlatform, PLATFORMS } from '../../services/authentication'
import { OPPORTUNITY_STATUSES, OPPORTUNITY_TYPES } from '../../services/opportunities'
import ResourcesListEdit from './../../components/deals/ResourcesListEdit'
import FundInvestmentEdit from './../../components/deals/FundInvestmentEdit'
import ValuationEdit from './../../components/deals/ValuationEdit'
import KeyPointsEdit from './../../components/deals/KeyPointsEdit'
import {
  Content,
  PageTitle,
  Text,
  H4,
  Label,
  PageHeader,
  ExternalLink,
  Link
} from '../../constants/StyleComponents'
import { OpportunityStatusOptions } from '../../constants/OpportunityStatusOptions'
import { dateTimeFormat } from '../../services/TimeUtils'
import EditCommitment from '../../components/opportunity/EditCommitment'
import moment from 'moment'
import { dealName, dataTypes, dealTypes, dealType } from '../../services/utils'
import SavingProgress from '../../components/SavingProgress'
import emptyImage from 'assets/images/image.svg'
import { Table, TableHeader, TableRow, TD } from '../../components/core/Table'
import { dateFormat } from 'services/TimeUtils'
import HeaderNew from 'components/core/HeaderNew'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const CarryButton = styled(Button)`
  height: 22px;
  font-size: 14px;
  margin-bottom: 10px;
`

const DealPageHeader = styled(PageHeader)`
  display: grid;
  grid-template-columns: 60% 30% 10%;
  padding: 0 0 20px 0;
  border-bottom: ${sv.borderStyle};
`

const StyledPageTitle = styled(PageTitle)`
  margin: 0 auto 0 0;
`

const Details = styled.div`
  ${sv.flexRow};
  align-items: flex-start;
`

const Info = styled.div`
  flex: 2;
  margin-right: ${sv.titlePadding}px;
`

const LogoContainer = styled.div`
  width: 50%;
  height: 120px;
  ${sv.borderRadius};
  background: ${props => props.bgColor};
  ${sv.flexCenter};
  margin-right: 10%;

  img {
    height: 60%;
  }
`

const CustomMediaItem = styled(MediaItem)``

const SmallLabel = styled.div`
  font-weight: normal;
  font-size: 14px;
`

const ModalEditButton = styled.div`
  color: #1d6aed;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
`

const Stats = styled.div`
  ${sv.flexRow};
`

const Stat = styled.div`
  align-items: center;
  margin-bottom: 5vh;
  max-width: 160px;
  padding: 5px;
  text-align: center;

  b {
    font-size: clamp(0.75rem, 0.6071rem + 0.7143vw, 1.25rem);
  }
`

const Description = styled(Text)`
  display: block;
  margin-bottom: ${sv.appPadding}px;
`

const CompanySite = styled.div`
  margin: ${sv.appPadding}px 0;
`

const KeyPoints = styled.ul`
  margin: 0 0 ${sv.appPadding}px 0;
  padding: 0;
  list-style-position: inside;
`

const KeyPoint = styled.li`
  padding: 0;
  margin-bottom: ${sv.grid}px;
`

const Resources = styled.div`
  margin: 0 0 ${sv.appPadding}px 0;
`

const Actions = styled.div`
  flex: 1;
`

const Commitments = styled.div`
  margin-top: ${sv.appPadding}px;
  border-bottom: ${sv.borderStyle};
`

const Commitment = styled.div`
  ${sv.flexRow};
  padding: ${sv.grid * 2}px 0;
  border-top: ${sv.borderStyle};
`

const Client = styled.div`
  flex: 1;
`

const Chip = styled.div`
  display: inline;
  background-color: #ccc;
  padding: 1px 12px;
  border-radius: 10px;
  margin-right: 2px;
`
const EditIcon = styled(FeatherIcon)`
  margin-left: ${sv.grid * 2}px;
  color: ${sv.colors.subtleText};
  cursor: pointer;

  &:hover {
    color: ${sv.colors.cta};
  }
`
const ValuationTable = styled(Table)`
  td {
    padding: 3px;
  }
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function Deal({ history }) {
  const { id } = useParams()
  const [isScrolled, setIsScrolled] = useState(false)
  const [deal, setDeal] = useState(null)
  const [commitments, setCommitments] = useState([])
  const [groupedCommitments, setGroupedCommitments] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [showEditCommitment, setShowEditCommitment] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [showKeyModal, setShowKeyModal] = useState(false)
  const [showDueDiligence, setShowDueDiligenceModal] = useState(false)
  const [showCompanyUpdates, setShowCompanyUpdatesModal] = useState(false)
  const [showVideosModal, setShowVideosModal] = useState(false)
  const [showDataRoom, setShowDataRoomModal] = useState(false)
  const [showFundInvesment, setShowFundInvesment] = useState(false)
  const [showValuation, setShowValuation] = useState(false)
  const [disabledStatus, setDisabledStatus] = useState(false)
  const [fundInvestmentItems, setFundInvestmentItems] = useState([])
  const [valuationItems, setValuationItems] = useState([])
  const dealFunds = deal?.funds?.map(item => {
    return {
      name: item.fund_name,
      opportunity_id: item.fund_id,
      investment_value: item.investment_value,
      reserve_amount: item.reserve_amount,
      total_investment_value: +item.reserve_amount + +item.investment_value
    }
  })

  const loadOpportunity = useCallback(() => {
    return (async () => {
      // the ID could be an integer or a slug for url friendly links.
      // always pull from the db since Datastore can't handle the slugs as IDs
      // http://api.js-data.io/js-data/latest/DataStore.html#clear__anchor
      DataStore.clear()
      const opp = await DataStore.find('opportunities', id)
      console.log(opp)
      setDeal(opp)
    })()
  }, [id])

  const groupCommitments = c =>
    c.reduce((res, elem) => {
      res[elem.organization.id] = [...(res[elem.organization.id] || []), elem]
      return res
    }, {})

  const loadCommits = useCallback(() => {
    if (deal) {
      DataStore.findAll('commitments', { opportunity_id: deal.id }).then(results => {
        setCommitments(results)
        const auxCommitments = groupCommitments(results)
        setGroupedCommitments(auxCommitments)
      })
    }
  }, [deal])

  useEffect(() => {
    loadOpportunity()
  }, [id, loadOpportunity])

  useEffect(() => {
    setIsActive(!!deal && deal.status === OPPORTUNITY_STATUSES.ACTIVE)
    setFundInvestmentItems(dealFunds)
    setValuationItems(deal?.valuation)
    loadCommits()
  }, [deal, setIsActive, loadCommits])

  const headerAction = {
    label: 'back',
    icon: 'arrow-left',
    action: () => history.push('/admin/deals')
  }

  /**
   * Call when finished editing deal
   */
  function dealEditDone() {
    loadOpportunity().then(() => {
      setShowEditModal(false)
    })
  }

  /**
   * Call when status is changed
   *
   * @param {object} option - The selected option
   * @returns {Promise}
   */
  function onStatusChange(option) {
    setDisabledStatus(true)
    deal.status = option.value
    setIsActive(!!deal && deal.status === OPPORTUNITY_STATUSES.ACTIVE)
    deal.save().then(() => {
      setDisabledStatus(false)
      setDeal(prevState => {
        const opp = Object.assign(new Opportunity(), prevState)
        opp.status = option.value
        return opp
      })
    })
  }

  function toggleDealFlag(name, value) {
    deal[name] = value
    deal.save().then(() => {
      setDeal(prevState => {
        const opp = Object.assign(new Opportunity(), prevState)
        opp[name] = value
        return opp
      })
    })
  }

  function updateLocalCommitments(updatedCommitment) {
    if (updatedCommitment) {
      const newCommitments = commitments.map(old =>
        old.id === updatedCommitment.id ? updatedCommitment : old
      )
      setCommitments(newCommitments)
      const auxCommitments = groupCommitments(newCommitments)
      setGroupedCommitments(auxCommitments)
    }
  }

  const companyLogo = deal?.logo?.url
    ? configuration.apiBaseUrl + deal?.logo?.url
    : deal?.logo_light?.url
    ? configuration.apiBaseUrl + deal?.logo_light?.url
    : emptyImage
  const dataRoomItems = deal && deal.links.filter(l => l.group === dataTypes.data_room)

  return (
    <>
      {deal && (
        <ScrollContainer hasHeader isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
          {/* <Header onAdmin isScrolled={isScrolled} leftAction={headerAction} /> */}
          <HeaderNew />

          <Content isAdmin>
            <DealPageHeader>
              <StyledPageTitle>{dealName(deal)}</StyledPageTitle>
              {deal && (
                <LogoContainer bgColor={deal.background_color}>
                  <img
                    src={companyLogo}
                    alt={deal.name}
                    onError={e => (e.target.src = emptyImage)}
                  />
                </LogoContainer>
              )}
              <Button label='Edit' action={() => setShowEditModal(true)} />
              {deal?.parent_deal && (
                <b>
                  <Link to={`/admin/deals/${deal?.parent_deal.slug}`}>
                    Initial deal: {dealName(deal?.parent_deal)}
                  </Link>
                </b>
              )}
            </DealPageHeader>
            <Details>
              <Info>
                <Stats>
                  <Stat>
                    <Label>Opportunity</Label>
                    <b>
                      <Link to={`/opportunities/${deal.id}`}>{deal.id}</Link>
                    </b>
                  </Stat>
                  <Stat>
                    <Label>Type</Label>
                    <b>{dealType(deal)}</b>
                  </Stat>
                  {deal?.isDealshare() && (
                    <>
                      <Stat>
                        <Label>Stage</Label>
                        <H4>{deal.dealshare_stage?.name}</H4>
                      </Stat>
                      <Stat>
                        <Label>Stage Rollup</Label>
                        <H4>{deal.dealshare_stage_rollup?.name}</H4>
                      </Stat>
                      <Stat>
                        <Label>Sector</Label>
                        <H4>{deal.dealshare_economic_sector?.name}</H4>
                      </Stat>
                    </>
                  )}
                  {deal?.isVintage() && (
                    <Stats>
                      <Stat>
                        <Label>Current MVP Total</Label>
                        <b>
                          {dollarFormat(
                            commitments
                              .map(c => Number(c.final_amount_invested))
                              .reduce(function (a, b) {
                                return a + b
                              }, 0)
                          )}
                        </b>
                      </Stat>
                      {deal.associated_collection && (
                        <Stat>
                          <Label>{deal.associated_collection.name} Commitments</Label>
                          <b>{dollarFormat(deal.associated_collection.commitments)}</b>
                        </Stat>
                      )}
                      {deal.vintage_anticipated_amount && (
                        <Stat>
                          <Label>Anticipated Fund Size</Label>
                          <b>{dollarFormat(deal.vintage_anticipated_amount)}</b>
                        </Stat>
                      )}
                    </Stats>
                  )}
                </Stats>
                {deal?.tag_list && deal?.tag_list.length > 0 && (
                  <Resources>
                    <Label>Tags</Label>
                    {deal.tag_list.map((tag, index) => (
                      <Chip key={index}>{tag}</Chip>
                    ))}
                  </Resources>
                )}
                {deal?.isDealshare() && (fundInvestmentItems?.length > 0 || isAdmin()) && (
                  <Resources>
                    <Label>
                      Fund investment
                      {isAdmin() && (
                        <ModalEditButton onClick={() => setShowFundInvesment(true)}>
                          &nbsp;Edit
                        </ModalEditButton>
                      )}
                    </Label>
                    {fundInvestmentItems
                      ?.sort((a, b) => a.order - b.order)
                      ?.map(item => (
                        <React.Fragment key={item.opportunity_id}>
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: 'column',
                              marginBottom: '1rem'
                            }}
                          >
                            <Text>
                              <b>{item.name}: </b>
                              {dollarFormat(item.total_investment_value)}

                              <ul className='list-disc pl-8' style={{ margin: 0 }}>
                                <li>
                                  <b>Investment Period:</b> {dollarFormat(item.investment_value)}
                                </li>
                                <li>
                                  <b>Reserves:</b> {dollarFormat(item.reserve_amount)}
                                </li>
                              </ul>
                            </Text>
                          </div>
                        </React.Fragment>
                      ))}
                  </Resources>
                )}
                {(deal?.valuation || isAdmin()) && (
                  <>
                    <Resources>
                      <Label>
                        Valuation
                        {isAdmin() && deal.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE && (
                          <ModalEditButton onClick={() => setShowValuation(true)}>
                            &nbsp;Edit
                          </ModalEditButton>
                        )}
                      </Label>
                      {valuationItems && (
                        <ValuationTable>
                          {deal.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE && (
                            <>
                              <TableRow>
                                <TD>Total Fund Investment:</TD>
                                <TD>{dollarFormat(valuationItems?.fund_total)}</TD>
                                <TD>&nbsp;&nbsp;&nbsp;&nbsp;</TD>
                                <TD>Funds Wired</TD>
                                <TD>{dateFormat(valuationItems?.funding_date)}</TD>
                              </TableRow>
                              <TableRow>
                                <TD>Total Co-Invest:</TD>
                                <TD>{dollarFormat(valuationItems?.investment_total)}</TD>
                                <TD />
                                <TD>Initial Post-Money Valuation:</TD>
                                <TD>{dollarFormat(valuationItems?.initial_post_valuation)}</TD>
                              </TableRow>
                            </>
                          )}
                          <TableRow>
                            <TD>Total Initial Invested:</TD>
                            <TD>{dollarFormat(valuationItems?.total_invested)}</TD>
                            {deal.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE && (
                              <>
                                <TD />
                                <TD>Current Pre-Money Valuation:</TD>
                                <TD>{dollarFormat(valuationItems?.current_pre_valuation)}</TD>
                              </>
                            )}
                          </TableRow>
                          <TableRow>
                            <TD style={{ fontWeight: 'bold' }}>MOIC</TD>
                            <TD style={{ fontWeight: 'bold' }}>
                              {Number(valuationItems?.current_multiple).toFixed(2)}x
                            </TD>
                            {deal.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE && (
                              <>
                                <TD />
                                <TD />
                                <TD />
                              </>
                            )}
                          </TableRow>
                          <TableRow>
                            <TD style={{ fontWeight: 'bold' }}>Current Value:</TD>
                            <TD style={{ fontWeight: 'bold' }}>
                              {dollarFormat(valuationItems?.current_value)}
                            </TD>
                            {deal.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE && (
                              <>
                                <TD />
                                <TD />
                                <TD />
                              </>
                            )}
                          </TableRow>
                        </ValuationTable>
                      )}
                    </Resources>
                  </>
                )}
                {isSuperAdmin() && Object.keys(groupedCommitments).length > 0 && (
                  <>
                    <CarryButton
                      label='View Carry'
                      action={() => history.push(`/admin/deals/${deal.slug}/carries`)}
                    />
                    {deal?.carry_tracking?.overall?.carry > 0 && (
                      <Resources>
                        <Label>Carry Tracking</Label>
                        <Table>
                          <TableHeader>
                            <TD />
                            <TD>Initial Invest</TD>
                            <TD>Fees</TD>
                            <TD>Final Total</TD>
                            <TD>Value</TD>
                            <TD>Carry</TD>
                          </TableHeader>
                          <TableRow>
                            <TD></TD>
                            <TD>{dollarFormat(deal.carry_tracking.overall.init_inv)}</TD>
                            <TD>{dollarFormat(deal.carry_tracking.overall.fees)}</TD>
                            <TD>{dollarFormat(deal.carry_tracking.overall.total)}</TD>
                            <TD>{dollarFormat(deal.carry_tracking.overall.value)}</TD>
                            <TD>{dollarFormat(deal.carry_tracking.overall.carry)}</TD>
                          </TableRow>
                        </Table>
                      </Resources>
                    )}
                  </>
                )}
                <Description>{ReactHtmlParser(deal.long_description_html)}</Description>
                {deal.company_url && (
                  <CompanySite>
                    <Label>Company Website</Label>
                    <ExternalLink href={deal.company_url} target='_blank'>
                      {deal.company_url}
                    </ExternalLink>
                  </CompanySite>
                )}

                <KeyPoints>
                  <Label>
                    Key Points{' '}
                    {isAdmin() && (
                      <ModalEditButton onClick={() => setShowKeyModal(true)}>
                        &nbsp;Edit
                      </ModalEditButton>
                    )}
                  </Label>
                  {deal.key_points
                    .sort((a, b) => a.order - b.order)
                    .map((point, index) => (
                      <React.Fragment key={'k' + index}>
                        <KeyPoint>{ReactHtmlParser(point.text_html)}</KeyPoint>
                      </React.Fragment>
                    ))}
                </KeyPoints>
                <Resources>
                  <Label>
                    Due Diligence{' '}
                    {isAdmin() && (
                      <ModalEditButton onClick={() => setShowDueDiligenceModal(true)}>
                        &nbsp;Edit
                      </ModalEditButton>
                    )}
                  </Label>
                  {deal.links
                    .filter(l => l.group === dataTypes.due_diligence)
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => {
                      return (
                        <div key={index}>
                          {(isAdmin() ||
                            (deal?.isVintage() && currentPlatform() !== 'champion') ||
                            (deal?.isCollection() && currentPlatform() === 'champion')) && (
                            <CustomMediaItem
                              item={item}
                              opportunity={deal}
                              history={history}
                              showMVP={isAdmin() && item.visible_on_mvp}
                              showChampion={isAdmin() && item.visible_on_champion}
                            />
                          )}
                        </div>
                      )
                    })}
                </Resources>
                {((dataRoomItems && dataRoomItems.length > 0) || isAdmin()) && (
                  <Resources>
                    <Label>
                      Data Room{' '}
                      {isAdmin() && (
                        <ModalEditButton onClick={() => setShowDataRoomModal(true)}>
                          &nbsp;Edit
                        </ModalEditButton>
                      )}
                    </Label>
                    {dataRoomItems &&
                      dataRoomItems
                        .sort((a, b) => a.order - b.order)
                        .map((item, index) => (
                          <div key={index} style={{ display: 'flex', width: '100%' }}>
                            {(isAdmin() ||
                              (deal.mvp && currentPlatform() !== 'champion') ||
                              (deal.champion && currentPlatform() === 'champion')) && (
                              <CustomMediaItem
                                key={'d' + index}
                                item={item}
                                opportunity={deal}
                                history={history}
                                showMVP={isAdmin() && item.visible_on_mvp}
                                showChampion={isAdmin() && item.visible_on_champion}
                              />
                            )}
                          </div>
                        ))}
                  </Resources>
                )}
                <Resources>
                  <Label>
                    Company Updates{' '}
                    {deal.is_follow_on ? (
                      <div>Not available for follow on deals.</div>
                    ) : (
                      <>
                        {isAdmin() && (
                          <ModalEditButton onClick={() => setShowCompanyUpdatesModal(true)}>
                            &nbsp;Edit
                          </ModalEditButton>
                        )}
                      </>
                    )}
                  </Label>
                  {deal.links
                    .filter(l => l.group === dataTypes.company_updates)
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => {
                      return (
                        <div key={index}>
                          {(isAdmin() ||
                            (deal?.opportunity_type === dealTypes.Vintage &&
                              currentPlatform() !== PLATFORMS.CHAMPION) ||
                            (deal?.opportunity_type === dealTypes.Collection &&
                              currentPlatform() === PLATFORMS.CHAMPION)) && (
                            <CustomMediaItem
                              item={item}
                              opportunity={deal}
                              history={history}
                              showMVP={isAdmin() && item.visible_on_mvp}
                              showChampion={isAdmin() && item.visible_on_champion}
                            />
                          )}
                        </div>
                      )
                    })}
                </Resources>
                <Resources>
                  <Label>
                    Videos
                    {isAdmin() && (
                      <ModalEditButton onClick={() => setShowVideosModal(true)}>
                        &nbsp;Edit
                      </ModalEditButton>
                    )}
                  </Label>
                  {deal.links
                    .filter(l => l.group === dataTypes.video_link)
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => {
                      return (
                        <div key={index}>
                          {isAdmin() && (
                            <CustomMediaItem
                              item={item}
                              opportunity={deal}
                              history={history}
                              showMVP={isAdmin() && item.visible_on_mvp}
                              showChampion={isAdmin() && item.visible_on_champion}
                            />
                          )}
                        </div>
                      )
                    })}
                </Resources>
              </Info>

              <Actions>
                <StatusDropdown
                  options={OpportunityStatusOptions}
                  value={deal.status}
                  onChange={onStatusChange}
                  disabled={disabledStatus}
                />
                {moment(deal.closes).isValid() && (
                  <Text>
                    {deal.closed ? <span>Closed On:</span> : <span>Closing Date:</span>}
                    <b> &nbsp; {dateTimeFormat(deal.closes)}</b>
                  </Text>
                )}
                {isActive && (
                  <>
                    <br />
                    {!deal?.isCollection() && (
                      <>
                        <Text>MVP Site</Text>

                        <Checkbox
                          label='Opportunities Page'
                          toggle={() => {
                            toggleDealFlag('visible_on_mvp', !deal.visible_on_mvp)
                          }}
                          selected={deal.visible_on_mvp}
                        />
                        <Checkbox
                          label='Full Portfolio (Companies) Page'
                          toggle={() => {
                            toggleDealFlag('show_in_portfolio', !deal.show_in_portfolio)
                          }}
                          selected={deal.show_in_portfolio}
                          disabled={deal.is_follow_on}
                        />
                        <Checkbox
                          label='Partial Portfolio (Marketing) Page'
                          toggle={() => {
                            toggleDealFlag('show_on_marketing_page', !deal.show_on_marketing_page)
                          }}
                          selected={deal.show_on_marketing_page}
                          disabled={deal.is_follow_on}
                        />
                      </>
                    )}
                    {!deal?.isVintage() && (
                      <>
                        <Text>Champion Site</Text>

                        <Checkbox
                          label='Opportunities Page'
                          toggle={() => {
                            toggleDealFlag('visible_on_champion', !deal.visible_on_champion)
                          }}
                          selected={deal.visible_on_champion}
                        />
                        <Checkbox
                          label='Full Portfolio (Companies) Page'
                          toggle={() => {
                            toggleDealFlag('show_in_portfolio_champ', !deal.show_in_portfolio_champ)
                          }}
                          selected={deal.show_in_portfolio_champ}
                          disabled={deal.is_follow_on}
                        />
                        <Checkbox
                          label='Partial Portfolio (Marketing) Page'
                          toggle={() => {
                            toggleDealFlag(
                              'show_on_marketing_page_champ',
                              !deal.show_on_marketing_page_champ
                            )
                          }}
                          selected={deal.show_on_marketing_page_champ}
                          disabled={deal.is_follow_on}
                        />
                      </>
                    )}
                  </>
                )}

                <Commitments>
                  <Label>
                    Commitments <Link to={`/admin/deals/${deal.slug}/commitments`}>view</Link>
                  </Label>
                  {Object.keys(groupedCommitments).map((elem, index) => {
                    return (
                      <Fragment key={index}>
                        <Commitment>
                          <Client>
                            {groupedCommitments[elem][0].user?.person && (
                              <H4>
                                {groupedCommitments[elem][0].user.person.fname}{' '}
                                {groupedCommitments[elem][0].user.person.lname}
                              </H4>
                            )}
                            {groupedCommitments[elem].map((commitment, i) => (
                              <div key={i} className='flex items-center'>
                                {groupedCommitments[elem].length > 1 && (
                                  <SmallLabel style={{ display: 'inline' }}>
                                    {commitment.entity.name}:{' '}
                                  </SmallLabel>
                                )}
                                <Label noMargin style={{ display: 'inline' }}>
                                  {dollarFormat(commitment.amount_requested)}
                                </Label>
                                {commitment.closed && (
                                  <FeatherIcon icon='check' color={sv.colors.success} />
                                )}
                                <EditIcon
                                  icon='edit'
                                  size={14}
                                  onClick={() => setShowEditCommitment(commitment)}
                                />
                              </div>
                            ))}
                          </Client>
                        </Commitment>
                      </Fragment>
                    )
                  })}
                </Commitments>
              </Actions>
            </Details>
          </Content>
          <EditDealDialog
            open={showEditModal}
            deal={JSON.parse(JSON.stringify(deal))}
            close={dealEditDone}
            history={history}
          />
          {showKeyModal && (
            <Modal show={showKeyModal} close={() => setShowKeyModal(false)} shadeDoesNotClose>
              <KeyPointsEdit
                deal={JSON.parse(JSON.stringify(deal))}
                close={() =>
                  loadOpportunity().then(() => {
                    setShowKeyModal(false)
                  })
                }
              />
            </Modal>
          )}

          {showDueDiligence && (
            <Modal
              show={showDueDiligence}
              close={() => setShowDueDiligenceModal(false)}
              shadeDoesNotClose
            >
              <ResourcesListEdit
                dataType={dataTypes.DueDiligence}
                deal={JSON.parse(JSON.stringify(deal))}
                close={() =>
                  loadOpportunity().then(() => {
                    setShowDueDiligenceModal(false)
                  })
                }
                dataTypes={dataTypes}
              />
            </Modal>
          )}

          {showCompanyUpdates && (
            <Modal
              show={showCompanyUpdates}
              close={() => setShowCompanyUpdatesModal(false)}
              shadeDoesNotClose
            >
              <ResourcesListEdit
                dataType={dataTypes.CompanyUpdates}
                deal={JSON.parse(JSON.stringify(deal))}
                close={() =>
                  loadOpportunity().then(() => {
                    setShowCompanyUpdatesModal(false)
                  })
                }
                dataTypes={dataTypes}
              />
            </Modal>
          )}

          {showVideosModal && (
            <Modal show={showVideosModal} close={() => setShowVideosModal(false)} shadeDoesNotClose>
              <ResourcesListEdit
                dataType={dataTypes.Videos}
                deal={JSON.parse(JSON.stringify(deal))}
                close={() =>
                  loadOpportunity().then(() => {
                    setShowVideosModal(false)
                  })
                }
                dataTypes={dataTypes}
              />
            </Modal>
          )}
          {showMessage && (
            <SavingProgress
              saving={showMessage}
              success={showMessage}
              close={() => setShowMessage(false)}
              message={'insufficient data to show this deal'}
            />
          )}
          {showDataRoom && (
            <Modal show={showDataRoom} close={() => setShowDataRoomModal(false)} shadeDoesNotClose>
              <ResourcesListEdit
                dataType={dataTypes.DataRoom}
                deal={JSON.parse(JSON.stringify(deal))}
                close={() =>
                  loadOpportunity().then(() => {
                    setShowDataRoomModal(false)
                  })
                }
                dataTypes={dataTypes}
              />
            </Modal>
          )}
          {showFundInvesment && (
            <Modal
              show={showFundInvesment}
              close={() => setShowFundInvesment(false)}
              shadeDoesNotClose
              minHeight={'10vh'}
            >
              <FundInvestmentEdit
                deal={deal}
                dealFunds={fundInvestmentItems}
                updateFunds={setFundInvestmentItems}
                close={() =>
                  loadOpportunity().then(() => {
                    setShowFundInvesment(false)
                  })
                }
                history={history}
              />
            </Modal>
          )}
          {showValuation && (
            <Modal
              show={showValuation}
              close={() => setShowValuation(false)}
              shadeDoesNotClose
              minHeight={'10vh'}
            >
              <ValuationEdit
                deal={JSON.parse(JSON.stringify(deal))}
                close={() =>
                  loadOpportunity().then(() => {
                    setShowValuation(false)
                  })
                }
                history={history}
                valuationItems={valuationItems}
                setValuationItems={setValuationItems}
              />
            </Modal>
          )}
          <EditCommitment
            commitment={showEditCommitment}
            show={showEditCommitment}
            close={c => {
              updateLocalCommitments(c)
              setShowEditCommitment(false)
            }}
          />
        </ScrollContainer>
      )}
    </>
  )
}

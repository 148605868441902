import { authenticatedFetch, currentPlatform } from 'services/authentication'
import configuration from 'services/config'
import { Member, Organization, InvestorResponse } from 'types/api-types'
import { EntitiesResponse } from './get-api'

export interface SaveHubspotParams {
  email?: string
  person_fname?: string
  person_lname?: string
  register_token?: string
}
export async function saveHubspotContact(params: SaveHubspotParams) {
  const response = await fetch(configuration.apiUrl + 'save_hubspot', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({ ...params, platform: currentPlatform() })
  })

  if (response.ok) return response
  throw new Error('Something went wrong')
}

export interface LoginParams {
  email: string
  password: string
}
export async function logIn({ email, password }: LoginParams) {
  const response = await fetch(configuration.apiUrl + 'login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      platform: currentPlatform()
    },
    body: JSON.stringify({
      user: {
        username: email,
        password,
        platform: currentPlatform()
      }
    })
  })

  if (!response.ok) {
    const res = await response.json()
    const error =
      res.message ?? 'There was a problem connecting to the server. Please try again later.'
    throw new Error(error)
  }
  return response
}

export interface UpdateOrganizationParams {
  id: number
  userId: number
  name: string
}
export async function updateOrganizationName({ id, name }: UpdateOrganizationParams) {
  try {
    return (await authenticatedFetch(`organizations/${id}`, {
      body: JSON.stringify({ name }),
      method: 'put'
    })) as Organization
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface InviteMemberParams {
  id: number
  email: string
}
export async function inviteMemberToOrganization({ id, email }: InviteMemberParams) {
  try {
    return (await authenticatedFetch(`organizations/${id}/invite_member`, {
      body: JSON.stringify({
        email,
        role: 'user',
        first_name: 'Organization',
        last_name: 'Member'
      }),
      method: 'post'
    })) as { members: Member[]; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface RemoveMemberParams {
  id: number
  userId: number
  email: string
}
export async function removeMemberFromOrganization({ id, email }: RemoveMemberParams) {
  try {
    return (await authenticatedFetch(`organizations/${id}/remove_member`, {
      body: JSON.stringify({
        email
      }),
      method: 'post'
    })) as { message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function removeAdminMemberFromOrganization(id: number) {
  try {
    return await authenticatedFetch(`users/${id}/remove_as_admin`, { method: 'get' })
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface MergeMemberParams {
  organizationId: number
  memberId: number
}
export async function mergeMegerIntoOrganization(params: MergeMemberParams) {
  try {
    return (await authenticatedFetch(
      `organizations/${params.organizationId}/merge_member?merge_user_id=${params.memberId}`,
      {}
    )) as { members: Member[]; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface AddNewEntityParams {
  userId: number
  organizationId: number
  name: string
}
export async function addNewEntityName(params: AddNewEntityParams) {
  try {
    return (await authenticatedFetch(`entities`, {
      method: 'POST',
      body: JSON.stringify({
        name: params.name,
        user_id: params.userId,
        organization_id: params.organizationId
      })
    })) as { entity: EntitiesResponse; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface EditEntityParams {
  id: number
  name: string
}
export async function editEntityName({ id, name }: EditEntityParams) {
  try {
    return (await authenticatedFetch(`entities/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({ name, id })
    })) as { entity: EntitiesResponse; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export async function deleteEntityName(id: number) {
  try {
    return (await authenticatedFetch(`entities/${id}`, {
      method: 'DELETE'
    })) as { entity: EntitiesResponse; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface CreateClientPasswordParams {
  userId: number
  password: string
}
export async function createClientPassword({ userId, password }: CreateClientPasswordParams) {
  try {
    return await authenticatedFetch(`passwords/create_client_password`, {
      method: 'PUT',
      body: JSON.stringify({ id: userId, password })
    })
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export type ChangePasswordParams = {
  currentPassword: string
  newPassword: string
}
export async function changePassword(params: ChangePasswordParams) {
  const { currentPassword, newPassword } = params
  try {
    return authenticatedFetch('passwords/change', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        platform: currentPlatform()
      },
      body: JSON.stringify({
        current_password: currentPassword,
        new_password: newPassword
      })
    })
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

export interface CreateInvestorParams {
  id?: number | null
  name: string
}

export async function createInvestor({ id = null, name }: CreateInvestorParams) {
  try {
    return (await authenticatedFetch(`investors/${id || ''}`, {
      body: JSON.stringify({
        name
      }),
      method: id ? 'put' : 'post'
    })) as { investor: InvestorResponse; message: string }
  } catch (error) {
    if (error instanceof Error) throw error.message
    throw error
  }
}

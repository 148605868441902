import { useState, useEffect } from 'react'
import ScrollContainer from 'components/core/ScrollContainer'
import HeaderNew from 'components/core/HeaderNew'
import { Table } from 'components/core/v2/Table'
import { useGetInvestors } from 'hooks/api/useQuery.hooks'
import type { InvestorResponse } from 'types/api-types'
import { columns } from 'components/core/v2/investors/Columns'
import { useTablePagination, useTable, useTableSort } from 'hooks/useTable.hooks'
import { TablePagination } from 'components/core/v2/TablePagination'
import { InvestorsForm } from 'components/core/admin/InvestorsForm'

interface DefaultPagination {
  order_by: string
  limit: number
  offset: number
  current_page: number
  order?: string
}

const LOCALSTORAGE_NAME = 'mvp_investors_sq'

const DEFAULT_PAGINATION = {
  order_by: 'name',
  limit: 50,
  offset: 0,
  current_page: 1,
}

const DEFAULT_FILTERS: { pagination: DefaultPagination } = {
  pagination: DEFAULT_PAGINATION
}

const getLocalStorageSearchQuery = () => {
  const searchQuery = localStorage.getItem(LOCALSTORAGE_NAME)
  if (!searchQuery) return DEFAULT_FILTERS
  return JSON.parse(searchQuery) as { pagination: DefaultPagination }
}

export default function Investors() {
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const { pagination, onPaginationChange } = useTablePagination({
    pageIndex: getLocalStorageSearchQuery().pagination.current_page - 1,
    pageSize: getLocalStorageSearchQuery().pagination.limit
  })
  const { sorting, onSortingChange, orderBy, order } = useTableSort([
    {
      id: getLocalStorageSearchQuery().pagination.order_by,
      desc: !!(getLocalStorageSearchQuery().pagination.order === 'desc')
    }
  ])

  const { data, isLoading, isFetching } = useGetInvestors({
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    order_by: orderBy,
    order: order
  })

  const tableData = data?.data.map(d => ({ ...d, linkUrl: `/admin/investors/${d.id}` }))

  const table = useTable<InvestorResponse>({
    columns,
    data: tableData ?? [],
    // debugTable: true,
    manualSorting: true,
    manualPagination: true,
    onSortingChange,
    onPaginationChange,
    pageCount: Math.ceil(+(data?.count ?? 1) / pagination.pageSize),
    state: { pagination, sorting },
    isLoading: isLoading || isFetching
  })

  const saveToLocalStorage = () => {
    const pageinationToStore = {
      order_by: orderBy,
      order,
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      current_page: pagination.pageIndex + 1,
      admin_list: true
    }

    localStorage.setItem(
      LOCALSTORAGE_NAME,
      JSON.stringify({ pagination: pageinationToStore })
    )
  }

  useEffect(() => {
    saveToLocalStorage()
  }, [saveToLocalStorage])

  return (
    <ScrollContainer
      hasHeader
      className=''
      goToTop={undefined}
      setGoToTop={undefined}
      isScrolled={isScrolled}
      setIsScrolled={setIsScrolled}
    >
      <HeaderNew />

      <div className='max-w-[1500px] mx-auto px-4 isolate'>
        <div className='flex flex-col mdlg:items-center mdlg:flex-row mt-10'>
          <h1 className='m-0 text-4xl montserrat font-bold'>
            Investors
          </h1>

          <div className='flex items-center mt-4 mdlg:ml-auto'>
            <div className='ml-auto'>
              <InvestorsForm/>
            </div>
          </div>
        </div>

        <Table<InvestorResponse> table={table} className='mt-4 md:mt-8' isClickableRow />
        <TablePagination table={table} />
      </div>
    </ScrollContainer>
  )
}

import ReactCreatableSelect from 'react-select/creatable'
import { components, type Props, type DropdownIndicatorProps, OptionProps } from 'react-select'
import { ChevronDown, Check } from 'lucide-react'
import { cn } from 'services/utils'

function DropdownIndicator(props: DropdownIndicatorProps) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown />
    </components.DropdownIndicator>
  )
}

function Option(props: OptionProps) {
  return (
    <components.Option {...props}>
      <span className='flex items-center space-x-2 py-[6px] pr-[8px] pl-2'>
        {props.isSelected ? <Check className='w-4 h-4' /> : <span className='w-4 h-4' />}{' '}
        <span>{props.label}</span>
      </span>
    </components.Option>
  )
}

export function CreatableSelect(props: Props) {
  const { placeholder = 'Select', className, ...rest } = props

  return (
    <ReactCreatableSelect
      {...rest}
      placeholder={placeholder}
      className={cn('h-12', className ?? '')}
      styles={{
        control: (base, state) => ({
          ...base,
          borderColor: '#BCCDE5',
          borderRadius: '6px',
          height: '100%',
          fontSize: '14px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        }),
        valueContainer: (base, props) => ({
          ...base,
          padding: '8px 24px'
        }),
        placeholder: (base, props) => ({
          ...base,
          color: 'black'
        }),
        indicatorsContainer: (base, props) => ({
          marginRight: '14px'
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        option: (base, props) => ({
          ...base,
          padding: '0',
          fontSize: '14px',
          backgroundColor: props.isSelected ? 'none' : '',
          color: props.isSelected ? 'black' : 'black',
          '&:hover': {
            borderRadius: '8px',
            backgroundColor: 'rgb(241 245 249 / 1)'
          }
        })
      }}
      components={{ DropdownIndicator, Option }}
    />
  )
}

import { useState, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import styled from 'styled-components'
import { linkedInRegex, isValidEmail } from '../services/utils'
import sv from '../themes/styles'
import { Input } from 'components/ui/Input'
import { Label } from 'components/ui/Label'
import { Button } from 'components/ui/Button'
import SavingProgress from './SavingProgress'
import { Text } from '../constants/StyleComponents'
import LogoLetter from './LogoLetter'
import {
  isAdmin as currentUserIsAdmin,
  isSuperAdmin,
  reloadUserInfo,
  userInfo,
  validateEmail
} from '../services/authentication'
import { DataStore } from '../services/DataStore'
import Dropdown from './core/fields/Dropdown'
import { userKeyFactory } from 'helpers/api/factories/userKey'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Container = styled.div`
  position: relative;
  padding: ${sv.appPadding}px;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default function EditAccountInfo({ user, close, closeAll, userUpdated }) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [carry, setCarry] = useState(0)
  const [linkedinUrl, setLinkedinUrl] = useState('')
  const [platform, setPlatform] = useState(user?.platform)
  // const [address, setAddress] = useState('')
  // const [phone, setPhone] = useState('')
  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)
  const [successSaving, setSuccessSaving] = useState(false)
  const [message, setMessage] = useState(null)
  const [containerOverflow, setContainerOverflow] = useState('auto')
  const [loggedInUser, setLoggedInUser] = useState(null)
  const [dissabledSave, setDissabledSave] = useState(false)
  const [fieldsDisabled, setFieldsDisabled] = useState(false)
  const [msg, setMsg] = useState('')

  const queryClient = useQueryClient()

  const loggedInUserData = async () => {
    const data = await userInfo()
    setLoggedInUser(data)
  }

  const handleClose = () => {
    setError(null)
    close()
  }

  useEffect(() => {
    setFirstName(user?.person?.fname)
    setLastName(user?.person?.lname)
    setEmail(user?.username)
    setLinkedinUrl(user?.linkedin_url)
    if (isSuperAdmin()) {
      setCarry(user?.global_carry)
    }
    // setAddress(user.address)
    // setPhone(user.phone)
  }, [user])

  useEffect(() => {
    loggedInUserData()
  }, [])

  const _currentUserIsAdmin = currentUserIsAdmin()
  const allowChangePlatform =
    _currentUserIsAdmin &&
    user &&
    !user.is_admin &&
    !user.created_by_invite &&
    user?.id !== loggedInUser?.id

  const handleSave = async () => {
    setError(null)

    const promises = []
    const userProps = Object.assign({}, user)
    // only platform change or other items
    if (allowChangePlatform && platform !== user.platform) {
      userProps.change_platform = platform
    } else {
      if (linkedinUrl && linkedinUrl !== '' && !linkedInRegex.test(linkedinUrl)) {
        setError('Please enter a valid LinkedIn URL (ie: https://www.linkedin.com/in/...)')
        return false
      }
      if (!isValidEmail(email)) {
        setError('Please enter a valid email address')
        return false
      }
      userProps.person.fname = firstName ? firstName.trim() : ''
      userProps.person.lname = lastName ? lastName.trim() : ''
      userProps.linkedin_url = linkedinUrl ? linkedinUrl.trim() : ''
      userProps.email = email.trim()
      userProps.username = email.trim() // api calls it username
      userProps.username = email.trim()
      if (isSuperAdmin()) {
        userProps.global_carry = carry
      }
    }
    const userUpdatePromise = DataStore.update('users', user.id, userProps).then(res => {
      if (userProps.change_platform) {
        setMessage(res.message)
      }
    })

    promises.push(userUpdatePromise)

    Promise.all(promises)
      .then(async () => {
        setSuccessSaving(true)
        setSaving(true)

        document.getElementById('account_info').scrollTop = 0
        setContainerOverflow('hidden')

        const loggedInUserInfo = await userInfo()
        if (user.id === loggedInUserInfo.id) {
          reloadUserInfo()
        }
        // for updating the current edited object and list info
        // would be better to reload from returned info
        user.person.fname = firstName ? firstName.trim() : ''
        user.person.lname = lastName ? lastName.trim() : ''
        user.linkedin_url = linkedinUrl ? linkedinUrl.trim() : ''
        user.platform = platform
        user.email = email
        if (isSuperAdmin()) {
          user.global_carry = carry
          setCarry(user.global_carry)
        }
        setFirstName(user.person.fname)
        setLastName(user.person.lname)
        setEmail(user.username)
        setLinkedinUrl(user.linkedin_url)
        setPlatform(user.platform)

        userUpdated?.(user)

        // TODO: this should be handled outside this component
        await queryClient.invalidateQueries({
          queryKey: userKeyFactory.user(),
          refetchType: 'all'
        })
      })
      .catch(err => {
        setError(err.message || 'A problem was encountered updating the user')
        setSaving(false)
      })
  }

  const handleEmailValidation = () => {
    if (!email) {
      setError('Please enter an email address')
      setDissabledSave(true)
    } else if (email !== user.username) {
      if (!isValidEmail(email)) {
        setError('Please enter a valid email address')
        setDissabledSave(true)
      } else {
        validateEmail(email).then(r => {
          if (r) {
            setError(r)
            setDissabledSave(true)
          } else {
            setError('')
            setDissabledSave(false)
          }
        })
      }
    } else {
      setDissabledSave(false)
      setError(null)
    }
  }

  const handlePlatformChange = val => {
    setPlatform(val.value)
    if (val.value !== user.platform) {
      setMsg('Please complete the platform change before editing other fields.')
      setError('')

      setFieldsDisabled(true)
      setFirstName(user.person?.fname)
      setLastName(user.person?.lname)
      setEmail(user.username)
      setLinkedinUrl(user.linkedin_url)
      setDissabledSave(false)
    } else {
      setMsg('')
      setFieldsDisabled(false)
    }
  }

  const closeAfterSuccess = () => {
    setContainerOverflow('auto')
    setSaving(false)
    // setSuccessSaving(false)
    if (_currentUserIsAdmin && message) {
      closeAll()
    } else {
      handleClose()
    }
  }
  const dropdownOptions = [
    { value: 'champion', label: 'Champion' },
    { value: 'mvp', label: 'MVP' }
  ]

  return (
    <Container style={{ overflow: containerOverflow }} id='account_info'>
      <h2 className='flex items-center m-0 mb-7'>
        Edit account information{' '}
        {_currentUserIsAdmin && user?.platform && <LogoLetter platform={user.platform} />}
      </h2>
      {user && user.created_by_invite && user.account_owner && user.organization && (
        <p className='text-base m-0 mb-7'>
          User on account belonging to {user.account_owner.name} ({user.organization.name})
        </p>
      )}
      {msg && <Text>{msg}</Text>}
      {error && <Text color={sv.colors.warning}>{error}</Text>}
      {/* editing name/email address is disabled for now */}
      {allowChangePlatform && (
        <Dropdown
          label='Platform'
          value={platform}
          options={dropdownOptions}
          onChange={handlePlatformChange}
        />
      )}
      <div className='space-y-4'>
        <div className='space-y-2'>
          <Label htmlFor='firstName'>First name</Label>
          <Input
            id='firstName'
            value={firstName || ''}
            placeholder='First name'
            disabled={fieldsDisabled}
            inputSize='lg'
            onChange={e => setFirstName(e.target.value)}
          />
        </div>

        <div className='space-y-2'>
          <Label htmlFor='lastName'>Last name</Label>
          <Input
            id='lastName'
            value={lastName || ''}
            placeholder='Last name'
            disabled={fieldsDisabled}
            inputSize='lg'
            onChange={e => setLastName(e.target.value)}
          />
        </div>
        <div className='space-y-2'>
          <Label htmlFor='email'>Email</Label>
          <Input
            id='email'
            value={email || ''}
            placeholder='Email address'
            disabled={fieldsDisabled}
            inputSize='lg'
            onBlur={e => handleEmailValidation(e.target.value)}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className='space-y-2'>
          <Label htmlFor='linkedInUrl'>LinkedIn URL</Label>
          <Input
            id='linkedInUrl'
            value={linkedinUrl || ''}
            placeholder='LinkedIn URL'
            disabled={fieldsDisabled}
            inputSize='lg'
            onChange={e => setLinkedinUrl(e.target.value)}
          />
        </div>
      </div>
      {isSuperAdmin() && user?.id !== loggedInUser?.id && (
        <div className='space-y-2 mt-4'>
          <Label htmlFor='global_carry'>Global carry (%)</Label>
          <Input
            id='global_carry'
            minValue={0}
            maxValue={100}
            type='number'
            inputSize='lg'
            value={carry}
            onChange={e => setCarry(e.target.value)}
          />
        </div>
      )}
      {/*
        <Input
        label="address"
        value={address || ""}
        onChange={(value) => setAddress(value)}
        />
        <Input
        label="phone number"
        value={phone || ""}
        onChange={(value) => setPhone(value)}
        />
      */}
      <div className='flex justify-center sm:justify-end space-x-4 mt-6'>
        <Button className='flex-1' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='success' className='flex-1' disabled={dissabledSave} onClick={handleSave}>
          Save Changes
        </Button>
      </div>
      <SavingProgress
        saving={saving}
        success={successSaving}
        close={closeAfterSuccess}
        message={message}
      />
    </Container>
  )
}

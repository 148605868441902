import { type ButtonHTMLAttributes, forwardRef } from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'services/utils'

const buttonVariants = cva(
  'input-outline inline-flex items-center justify-center whitespace-nowrap text-base font-medium ring-offset-background transition-colors montserrat',
  {
    variants: {
      variant: {
        default: 'bg-[#10273A] text-white hover:opacity-90',
        success: 'bg-[#51b749] text-white hover:opacity-90',
        destructive: 'bg-red-500 text-white hover:bg-destructive/90',
        outline:
          'border border-[#10273A] bg-white hover:opacity-90 hover:bg-[#10273A] hover:text-white hover:opacity-90',
        companiesOutline:
          'px-4 rounded-md border-[#DEDEE3] relative !bg-white  py-2 sm:px-5 montserrat flex items-center sm:gap-1 font-[500] text-[12px] sm:text-[16px] sm:font-[600] border border-[#10273A] bg-white hover:opacity-90  hover:opacity-90',
        // secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-gray-50 hover:text-gray-800',
        link: 'textbg-[#10273A] underline-offset-4 hover:underline'
      },
      size: {
        default: 'h-12 px-4 py-4 rounded-lg',
        sm: 'h-10 px-2 rounded-md text-sm',
        lg: 'h-16 px-6 rounded-xl',
        icon: 'h-10 w-10'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
